import React from 'react';
import './../App/App.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FirstCard from '../InputCards/FirstCard';
import DenseAppBar from '../Header/Header';
import Footer from '../Footer/Footer';

function Main() {
  return (
    <div>
      <DenseAppBar></DenseAppBar>
    <Container maxWidth="lg" className="App">
    <Typography variant="h4" color="black" style={{margin: 50}}>
          Select which calculation you want to do
        </Typography>
      <FirstCard title={'From camera to scene'} descr={'You have chosen a camera and lens, but want to calculate the coverage'} img={'./assets/camscene.png'}/>
      <FirstCard title={'From scene to camera'} descr={'You know the dimensions of the scene and you are looking for the right camera and lens'} img={'./assets/scenecam.png'}/>
      <FirstCard title={'From exposure time to maximum blur'} descr={'You have a camera with specific exposure/shutter time and you want the maximum blur'} img={'./assets/timeblur.png'}/>
      <FirstCard title={'From maximum blur to exposure time'} descr={'You know the laximum blur and want to calculate the exposure/shutter time your camera needs'} img={'./assets/blurtime.png'}/>
      <FirstCard title={'Contact us'} descr={'Give us feedback or add new features'}/>
    </Container>
    <Footer></Footer>
    </div>
  );
}export default Main;