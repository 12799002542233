import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

/**
 * Shows different features to choose from
 * @returns 
 */
export default function ResultCard({FOV, cameraResolutionVert, cameraResolutionHor, focalLength, roundedResX, roundedResY, megaPixel, img, img2 }) {

  const focalLengthRounded = focalLength.toFixed(2)
  

  return (
    <Card sx={{ maxWidth: 900}} style={{ border: "3px solid #5BDCDF" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Exact results
        </Typography>
        {/* <Typography variant="body2" color="text.secondary">
          Field of View: {FOV}
        </Typography> */}
        <Typography variant="body2" color="text.secondary">
          Camera resolution: {cameraResolutionVert} x {cameraResolutionHor}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Rounded camera resolution: {roundedResX} x {roundedResY}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Rounded number of megapixels: {megaPixel} MP
        </Typography>
        <Typography gutterBottom variant="h5" component="div" style={{marginTop: 30}}>
        Lens settings
      </Typography>
        <Typography variant="body2" color="text.secondary">
        Focal length: {focalLengthRounded} mm
        </Typography>
        <Typography variant="body2" color="secondary">
        <img alt="" style={{ width: "100%", height: "", maxWidth: 500, marginTop: 50 }} src={img}></img>
        </Typography>
        <Typography variant="body2" color="secondary">
        <img alt="" style={{ width: "100%", height: "", maxWidth: 500, marginTop: 50 }} src={img2}></img>
        </Typography>
      </CardContent>  
      <div style={{display:"flex", justifyContent:"center", width: "100%"}}>

      </div>
    </Card>
  );
}