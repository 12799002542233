import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
// import { Alert } from 'react-alert'

/**
 * Shows different features to choose from
 * @returns 
 */
export default function SecondCard({descr, title, unit, descr1, title1, unit1, img}) {

  const navigate = useNavigate();

    const [size, setSize] = React.useState("");
    const [number, setNumber] = React.useState("");
    const [color, setcolor] = React.useState("blue")

  const handleChange = (e, type) => {
    console.log("hey")
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (type === "size"){
        setSize(e.target.value);
      } else if (type === "number"){
        setNumber(e.target.value);
      }
      
      console.log(number)
      console.log(size)
    } else {
        setcolor("red")
        console.log(color)
        
    }
  };

  const submit = () => {
    const regex = /^[0-9\b]+$/;
    if ((regex.test(size)) && (regex.test(number))){
      const smallestfeature = (size / number)
      navigate(`/nbpixels`,{ state: { smallestfeature: smallestfeature} })
    } else if (!regex.test(size)) {
      alert("Size of marker is not correct or not filled in, try again.")
    } else if (!regex.test(number)) {
      alert("Number of building blocks is not correct or not filled in, try again.")
    }
  }

  return (
    <Card sx={{ maxWidth: 900}} style={{ border: "3px solid #5BDCDF" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" >
          {title}
        </Typography>
        <Typography variant="body2" color="secondary">
          {descr}
        </Typography>
        <FormControl error={false}>
            <InputLabel htmlFor="my-input">{unit}</InputLabel>
            <Input id="my-input" aria-describedby="my-helper-text" onChange={(e) => handleChange(e, "size")} value={size}></Input>
            
            <FormHelperText id="my-helper-text">Only positive numbers are accepted.</FormHelperText>
        </FormControl>
        <Typography gutterBottom variant="h5" component="div" style={{marginTop: 100}}>
          {title1}
        </Typography>
        <Typography variant="body2" color="secondary">
          {descr1}
        </Typography>
        <FormControl error={false}>
            <InputLabel htmlFor="my-input1">{unit1}</InputLabel>
            <Input id="my-input1" aria-describedby="my-helper-text1" onChange={(e) => handleChange(e, "number")} value={number}></Input>
            
            <FormHelperText id="my-helper-text1">Only positive numbers are accepted.</FormHelperText>
        </FormControl>
        <Typography variant="body2" color="secondary">
        <img alt="" style={{ width: "100%", height: "", maxWidth: 500, marginTop: 50 }} src={img}></img>
        </Typography>

        

        </CardContent>
      <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
      <CardActions>
        <Button variant="contained" color="secondary" size="small" onClick={submit}>Go to the next step</Button>
      </CardActions>
      </div>
    </Card>
  );
}