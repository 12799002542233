import React from 'react';
import '../App/App.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DenseAppBar from '../Header/Header';
import Footer from '../Footer/Footer';
import SecondCard from '../InputCards/SecondCard';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import explanation from './../../Assets/QR-explanation.png';

function Marker() {

  const navigate = useNavigate();

  function goto() {
    navigate("/")
  }

  return (
    <div>
      <DenseAppBar></DenseAppBar>
    <Container maxWidth="lg" className="App">
    <Typography variant="h4" color="black" style={{margin: 50}}>
          Calculate size of smallest block of the marker
    </Typography>
    <SecondCard descr={"Size of marker"} title={"Size of marker"} unit={'0 mm'} descr1={"Number of building blocks"} title1={"Number of building blocks"} unit1={'0 #'} img={explanation}></SecondCard>
    <Button variant="contained" color="secondary" size="small" onClick={goto}>Start over (to home)</Button>
    </Container>
    <Footer></Footer>
    </div>
  );
}

export default Marker;