import React from 'react';
import '../App/App.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DenseAppBar from '../Header/Header';
import Footer from '../Footer/Footer';
import { Button, Card, CardActions, CardContent, FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

function Scene() {


    const navigate = useNavigate();

    const [time, setTime] = React.useState("");
    const [speed, setSpeed] = React.useState("");
    const [blur, setBlur] = React.useState("");

    function goto() {
        navigate("/")
      }

  const handleChangeDecimals = (e, type) => {
    console.log("hey")
    const regex = /^\d*\.?\d*$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
        if (type === "speed"){
            setSpeed(e.target.value);
        } else if (type === "time"){
            setTime(e.target.value);
        }
    }
}

  const submit = () => {
    const regex = /^\d*\.?\d*$/;
    if (!regex.test(speed)) {
    alert("Speed is not correct or not filled in, try again.")
  } else if (!regex.test(time)) {
    alert("Time is not correct or not filled in, try again.")
  } else if (speed === "" || time === "") {
    alert("Not all fields are filled in, try again.")
  } else if ((regex.test(speed)) && (regex.test(time))){
        // Calculate blur
        setBlur(speed * time)
    }
  }

  return (
    <div>
      <DenseAppBar></DenseAppBar>
    <Container maxWidth="lg" className="App">
    <Typography variant="h4" color="black" style={{margin: 50}}>
          Fill in the specifications to calculate the max blur
    </Typography>
    <Card sx={{ maxWidth: 900}} style={{ border: "3px solid #5BDCDF" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" >
        Speed of camera or object
        </Typography>
        <Typography variant="body2" color="secondary">
        The speed of the camera or object being photographed, typically measured in millimeters per second (mm/s), which affects the motion blur in the resulting image.
        </Typography>
        <FormControl error={false}>
            <InputLabel htmlFor="my-input">mm/s</InputLabel>
            <Input id="my-input" aria-describedby="my-helper-text" onChange={(e) => handleChangeDecimals(e, "speed")} value={speed}></Input>
            
            <FormHelperText id="my-helper-text">Only positive numbers are accepted.</FormHelperText>
        </FormControl>
        <Typography gutterBottom variant="h5" component="div" style={{marginTop: 100}}>
          Exposure/Shutter time
        </Typography>
        <Typography variant="body2" color="secondary">
        The exposure or shutter time of the camera, typically measured in seconds (s), which affects the amount of light captured and the motion blur in the resulting image.
        </Typography>
        <FormControl error={false}>
            <InputLabel htmlFor="my-input3">s</InputLabel>
            <Input id="my-input3" aria-describedby="my-helper-text3" onChange={(e) => handleChangeDecimals(e, "time")} value={time}></Input>
            
            <FormHelperText id="my-helper-text3">Only positive numbers are accepted.</FormHelperText>
        </FormControl>
        

        </CardContent>
      <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
      <CardActions>
        <Button variant="contained" color="secondary" size="small" onClick={submit}>Calculate the maximum blur</Button>
      </CardActions>
      </div>
    </Card>

    {blur !== "" && <>
    <Card>
    <Typography gutterBottom variant="h5" component="div">
          Exact results
        </Typography>
        {/* <Typography variant="body2" color="text.secondary">
          Field of View: {FOV}
        </Typography> */}
        <Typography variant="body2" color="text.secondary">
          Blur: {blur} mm
        </Typography>
    </Card></>}



    <Button variant="contained" color="secondary" size="small" onClick={goto}>Start over (to home)</Button>
    </Container>
    <Footer></Footer>
    </div>
  );
}

export default Scene;