import React from 'react';
import '../App/App.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DenseAppBar from '../Header/Header';
import Footer from '../Footer/Footer';
import ResultResolutionCard from '../InputCards/ResultResolutionCard';
import PickResolutionCard from '../InputCards/PickResolutionCard';
import { Button } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';

function PickPixelSize() {

  const {state} = useLocation();
  const { cameraResolutionVert, cameraResolutionHor, roundedResX, roundedResY, megaPixel, pixelSize, vertFOV, horFOV, workingDistance } = state; // Read values passed on state
  


  const navigate = useNavigate();

  function goto() {
    navigate("/")
  }

  return (
    <div>
      <DenseAppBar></DenseAppBar>
    <Container maxWidth="lg" className="App">
    <Typography variant="h4" color="black" style={{margin: 50}}>
          Results of the recommended camera resolution
    </Typography>
    <ResultResolutionCard cameraResolutionHor={cameraResolutionHor} cameraResolutionVert={cameraResolutionVert} roundedResX={roundedResX} roundedResY={roundedResY} megaPixel={megaPixel}></ResultResolutionCard>
    <Typography variant="h4" color="black" style={{margin: 50}}>
          Further calculations for lens and exposure time
    </Typography>
    <PickResolutionCard cameraResolutionHor={cameraResolutionHor} cameraResolutionVert={cameraResolutionVert} roundedResX={roundedResX} roundedResY={roundedResY} megaPixel={megaPixel} vertFOV={vertFOV} horFOV={horFOV} workingDistance={workingDistance} pixelSize={pixelSize}></PickResolutionCard>
    <Button variant="contained" color="secondary" size="small" onClick={goto}>Start over (to home)</Button>
    </Container>
    <Footer></Footer>
    </div>
  );
}

export default PickPixelSize;