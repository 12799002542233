import React from 'react';
import './App.css';

import { Routes, Route } from "react-router-dom";
import Marker from './../pages/Marker';
import NumberOfPixels from './../pages/NumberOfPixels';
import Results from './../pages/Results';
import Scratch from './../pages/Scratch';
import SendMail from './../pages/SendMail';
import DynamicObject from './../pages/DynamicObject';
import DynamicCamera from './../pages/DynamicCamera';
import ListOfSuppliers from './../pages/ListOfSuppliers';
import PickPixelSize from './../pages/PickPixelSize';
import Home from './../pages/Home';
import Main from './../pages/Main';
import Scene from '../pages/Scene';
import Blur from '../pages/Blur';
import Exposure from '../pages/Exposure';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/home" element={<Home />} />
        <Route path="/scene" element={<Scene />} />
        <Route path="/exposure" element={<Exposure />} />
        <Route path="/blur" element={<Blur />} />
        <Route path="/marker" element={<Marker />} />
        <Route path="/scratch" element={<Scratch />} />
        <Route path="/contact" element={<SendMail />} />
        <Route path="/nbpixels" element={<NumberOfPixels />} />
        <Route path= "/result" element={<Results />}/>
        <Route path= "/dynamicobjects" element={<DynamicObject />}/>
        <Route path= "/dynamiccamera" element={<DynamicCamera />}/>
        <Route path= "/listofsuppliers" element={<ListOfSuppliers />}/>
        <Route path= "/pickpixelsize" element={<PickPixelSize />}/>
      </Routes>
    </div>
  );
}export default App;