import React from 'react';
import '../App/App.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DenseAppBar from '../Header/Header';
import Footer from '../Footer/Footer';
import { Button } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import dynamiccamera from './../../Assets/dynamiccamera.png';
import DynamicCameraCard from '../InputCards/DynamicCameraCard';

function DynamicCamera() {

  const navigate = useNavigate();

  const {state} = useLocation();
  const { FOV, cameraResolutionVert, cameraResolutionHor, focalLength, roundedResX, roundedResY,megaPixel,sensorSize, resolutionsize} = state; // Read values passed on state

  function goto() {
    navigate("/")
  }

  return (
    <div>
      <DenseAppBar></DenseAppBar>
    <Container maxWidth="lg" className="App">
    <Typography variant="h4" color="black" style={{margin: 50}}>
          Moving camera
    </Typography>
    <DynamicCameraCard FOV={FOV} resolutionsize={resolutionsize} cameraResolutionHor={cameraResolutionHor} cameraResolutionVert={cameraResolutionVert} focalLength={focalLength} roundedResX={roundedResX} roundedResY={roundedResY} megaPixel={megaPixel} sensorSize={sensorSize} title={"Maximum allowed image blur"} descr={"Subjective measure of the acceptable degree of blurriness in an image, typically determined by factors such as image quality standards or user preferences."} unit={"number of pixels"} title2={"Linear speed of objects"} descr2={"For example the (linear) speed of a conveyor belt with moving objects on it"} unit2={"mm/s"} img={dynamiccamera}></DynamicCameraCard>
    <Button variant="contained" color="secondary" size="small" onClick={goto}>Start over (to home)</Button>
    </Container>
    <Footer></Footer>
    </div>
  );
}

export default DynamicCamera;