import React from 'react';
import '../App/App.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DenseAppBar from '../Header/Header';
import Footer from '../Footer/Footer';
import MailCard from '../InputCards/MailCard';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

function SendMail() {

  const navigate = useNavigate();

  function goto() {
    navigate("/")
  }

  return (
    <div>
      <DenseAppBar></DenseAppBar>
    <Container maxWidth="lg" className="App">
    <Typography variant="h4" color="black" style={{margin: 50}}>
          Send mail with possible improvements
    </Typography>
    <MailCard></MailCard>
    <Button variant="contained" color="secondary" size="small" onClick={goto}>Start over (to home)</Button>
    </Container>
    <Footer></Footer>
    </div>
  );
}

export default SendMail;