import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
// import App from './Components/App/App';
import theme from './theme';
import App from './Components/App/App';
// import Marker from './Components/pages/Marker';
// import NumberOfPixels from './Components/pages/NumberOfPixels';
// import Results from './Components/pages/Results';
// import Scratch from './Components/pages/Scratch';
// import SendMail from './Components/pages/SendMail';
// import DynamicObject from './Components/pages/DynamicObject';
// import DynamicCamera from './Components/pages/DynamicCamera';
// import ListOfSuppliers from './Components/pages/ListOfSuppliers';
// import PickPixelSize from './Components/pages/PickPixelSize';
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <BrowserRouter>
    {/* <Navigation /> */}
      {/* <Routes>
        <Route path="/" element={<App />} />
        <Route path="/marker" element={<Marker />} />
        <Route path="/scratch" element={<Scratch />} />
        <Route path="/contact" element={<SendMail />} />
        <Route path="/nbpixels" element={<NumberOfPixels />} />
        <Route path= "/result" element={<Results />}/>
        <Route path= "/dynamicobjects" element={<DynamicObject />}/>
        <Route path= "/dynamiccamera" element={<DynamicCamera />}/>
        <Route path= "/listofsuppliers" element={<ListOfSuppliers />}/>
        <Route path= "/pickpixelsize" element={<PickPixelSize />}/>
      </Routes> */}
    {/* <App /> */}
    <App></App>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
