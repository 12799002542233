import { useEffect, useState } from "react";
import { TextField, Button, Typography, Box } from "@material-ui/core";
import emailjs from "@emailjs/browser";
import { useNavigate } from 'react-router-dom';

export default function ContactForm() {
  const navigate = useNavigate();
  
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => emailjs.init("m_i2yH1rjA760rLbV"), []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //

    const serviceId = "service_28oo4db";
    const templateId = "template_vwy1nup";
    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, {
       name: name,
       mail: email,
       message: message
      });
      alert("email successfully sent check inbox");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      navigate("/")
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: 600,
          mx: "auto",
          p: 2,
          border: "2px solid  #000000",
          borderRadius: "12px",
          boxShadow: 1,
        }}
      >
        <Typography variant="h4" align="center" mb={2}>
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            type="email"
          />
          <TextField
            fullWidth
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            margin="normal"
            required
            multiline
            rows={4}
          />
          <Button
            fullWidth
            disabled={loading}
            type="submit"
            sx={{
              mt: 2,
              backgroundColor: "#5BDCDF",
              color: "#5BDCDF",
              "&:hover": {
                backgroundColor: "#5BDCDF",
              },
            }}
          >
            Submit
          </Button>
        </form>
      </Box>
    </Box>
  );
}