import React from 'react';
import '../App/App.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DenseAppBar from '../Header/Header';
import Footer from '../Footer/Footer';
import { Button } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import ListOfSuppliersCard from '../InputCards/ListOfSuppliersCard';
import ResultsDynamicObject from './../InputCards/ResultsDynamicObject'

function ListOfSuppliers() {

  const navigate = useNavigate();

  const {state} = useLocation();
  const { maxexposuretime, FOV, cameraResolutionVert, cameraResolutionHor, focalLength, roundedResX, roundedResY, megaPixel, sensorSize, staticObject} = state; // Read values passed on state
  console.log(maxexposuretime)
  console.log(cameraResolutionHor)
  console.log(cameraResolutionVert)
  console.log(sensorSize)


  function goto() {
    navigate("/")
  }

  return (
    <div>
      <DenseAppBar></DenseAppBar>
    <Container maxWidth="lg" className="App">
    <Typography variant="h4" color="black" style={{margin: 50}}>
          List of possible suppliers
    </Typography>
    <ResultsDynamicObject staticObject={staticObject} maxexposuretime={maxexposuretime} FOV={FOV} cameraResolutionHor={cameraResolutionHor} cameraResolutionVert={cameraResolutionVert} focalLength={focalLength} roundedResX={roundedResX} roundedResY={roundedResY} megaPixel={megaPixel}></ResultsDynamicObject>
    <ListOfSuppliersCard></ListOfSuppliersCard>
    <Button variant="contained" color="secondary" size="small" onClick={goto}>Start over (to home)</Button>
    </Container>
    <Footer></Footer>
    </div>
  );
}

export default ListOfSuppliers;