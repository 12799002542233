import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
// import { Image } from '@material-ui/icons';
import logoHeader from './../../Assets/FM_logo_0.png'

export default function DenseAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          {/* <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton> */}
          
          
          <Typography variant="body1" inline color='inherit' component="div">
            <img src={logoHeader} height={100} alt="header logo"/>
          </Typography>
          <div style={{display:"flex", justifyContent:"center", width: "75%"}}>
          <Typography variant="h6" color='inherit' component="div">
            <p>Vision System Hardware Selection</p>
          </Typography>
          </div>
          
        </Toolbar>
      </AppBar>
    </Box>
  );
}