import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';
// import { Alert } from 'react-alert'

/**
 * Shows different features to choose from
 * @returns 
 */
export default function ListOfSuppliersCard() {

  const navigate = useNavigate();

    const list = [{id: 1, title: "IDS", url: "https://en.ids-imaging.com/", img:"https://en.ids-imaging.com/store/skin/frontend/ids/default//files/layout/ids-logo.svg"}, 
    {id: 2, title: "Basler", url: "https://www.baslerweb.com/en/", img: "https://media.fortop.be/public/files/products/basler/basler-logo-590-415.png"}, 
    {id: 3, title: "Kowa", url: "https://www.kowa-lenses.com/en/", img: "https://www.kowa-lenses.com/media/unknown/b5/d3/42/logo_kowa.svg"}]

    function goto() {
      navigate("/contact")
    }

  return (
    <Card sx={{ maxWidth: 900}} style={{ border: "3px solid #5BDCDF" }}>
      <CardContent>
      <Typography gutterBottom variant="h5" component="div">
          Choose a supplier:
        </Typography>


        {list.map(listItem => 
        <div key={listItem.id}>
          <a href={listItem.url}>
            <Typography gutterBottom variant="h5" component="div" >
            {listItem.id + ") " + listItem.title}
          </Typography>
          <Typography variant="body2" color="secondary">
        <img alt="" style={{ width: "100%", height: "", maxWidth: 100, marginTop: 20, marginBottom: 50 }} src={listItem.img}></img>
        </Typography>
        </a>
          </div>
          
            )}
            <Button variant="contained" color="secondary" size="small" onClick={goto}>I want to add a new supplier</Button>
            <Typography gutterBottom variant="h7" component="div" style={{marginTop: 20}}>
            You can also find a list of suppliers on <a href="https://services.flandersmake.be/confluence/display/CLProductionS/Suppliers++by+industry+sector">Confluence</a>.
          </Typography>

        </CardContent>
    </Card>
  );
}