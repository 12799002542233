import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
// import { Alert } from 'react-alert'

/**
 * Shows different features to choose from
 * @returns 
 */
export default function ThirdCard({smallestfeature, img}) {

  const navigate = useNavigate();

    const [vert, setVert] = React.useState("");
    const [hor, setHor] = React.useState("");
    const [depth, setDepth] = React.useState("");
    const [pixels, setPixels] = React.useState("");
    const [color, setcolor] = React.useState("blue");
    // const resolutionX = 0
    // const resolutionY = 0

    const listOfResolutionX = [640, 800, 1024, 1280, 1600, 2048, 2048, 2452, 3200, 1280, 1920]
    const listOfResolutionY = [480, 600, 768, 1024, 1200, 1536, 2048, 2054, 2400, 720, 1080]
    const megapixels = [0.3, 0.48, 0.78, 1.3, 1.92, 3.1, 4.0, 5.0, 7.7, 0.92, 2.1]


  const handleChange = (e, type) => {
    console.log("hey")
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (type === "vert"){
        setVert(e.target.value);
      } else if (type === "hor"){
        setHor(e.target.value);
      } else if (type === "depth"){
        setDepth(e.target.value);
      } else if (type === "pixels"){
        setPixels(e.target.value);
      }
    } else {
        setcolor("red")
        console.log(color)
    }
  };


  // https://www.vision-doctor.com/en/camera-technology-basics/resolution-of-sensors.html
  function calculateclosest(x, y){
    var smallestDiff = 9999
    var tempX = x
    var tempY = y
    var megaPixel = x*y/1000000

    for (var i = 0; i < listOfResolutionX.length; i++){
      console.log("resX: " + listOfResolutionX[i])
      if (listOfResolutionX[i] > x){
        if (listOfResolutionY[i] > y){
          if (smallestDiff > listOfResolutionX[i] - x + listOfResolutionY[i] - y){
            smallestDiff = listOfResolutionX[i] - x + listOfResolutionY[i] - y

            tempX = listOfResolutionX[i]
            tempY = listOfResolutionY[i]
            megaPixel = megapixels[i]
            
          }
        }
      }
    }
    console.log("tempX: " + tempX)
    console.log("tempY: " + tempY)
    return [tempX, tempY, megaPixel]
  }

  // calculateclosest(cameraResolutionHor, cameraResolutionVert)
  

  const submit = () => {
    const regex = /^[0-9\b]+$/;
    if ((regex.test(vert)) && (regex.test(hor)) && (regex.test(depth)) && (regex.test(pixels))){
      const requiredPixelSize = smallestfeature / pixels
      const cameraResolutionVert = vert / requiredPixelSize
      const cameraResolutionHor = hor / requiredPixelSize
      let resolutions = calculateclosest(cameraResolutionHor, cameraResolutionVert)
      const resolutionX = resolutions[0], resolutionY = resolutions[1], megaPixel = resolutions[2];

      console.log("results: " + resolutionX + " " + resolutionY)

        
      navigate(`/pickpixelsize`,{ state: {cameraResolutionVert: cameraResolutionVert, cameraResolutionHor: cameraResolutionHor, roundedResX: resolutionX, roundedResY: resolutionY, megaPixel: megaPixel, pixelSize:requiredPixelSize, vertFOV: vert, horFOV: hor, workingDistance: depth } })
    } else if (!regex.test(vert)) {
      alert("Vertical distance of scene is not correct or not filled in, try again.")
    } else if (!regex.test(hor)) {
        alert("Horizontal distance of scene is not correct or not filled in, try again.")
    } else if (!regex.test(depth)) {
        alert("Distance of scene from/to camera is not correct or not filled in, try again.")
    } else if (!regex.test(pixels)) {
        alert("Number of pixels of smallest feature is not correct or not filled in, try again.")
    } 
  }

  return (
    <Card sx={{ maxWidth: 900}} style={{ border: "3px solid #5BDCDF" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" >
          Scene dimensions
        </Typography>
        <Typography variant="body2" color="secondary">
          Give the vertical and horizontal dimensions of the scene and also the distance from/to the camera.
        </Typography>
        <FormControl error={false} style={{marginRight: 30, marginLeft: 30}}>
            <InputLabel htmlFor="my-input-vert">Vertical or height (in mm)</InputLabel>
            <Input id="my-input-vert" aria-describedby="my-helper-text-vert" onChange={(e) => handleChange(e, "vert")} value={vert}></Input>
            
            <FormHelperText id="my-helper-text-vert">Only positive numbers are accepted.</FormHelperText>
        </FormControl>
        <FormControl error={false} style={{marginRight: 30, marginLeft: 30}}>
            <InputLabel htmlFor="my-input-hor">Horizontal or width (in mm)</InputLabel>
            <Input id="my-input-hor" aria-describedby="my-helper-text-hor" onChange={(e) => handleChange(e, "hor")} value={hor}></Input>
            
            <FormHelperText id="my-helper-text-hor">Only positive numbers are accepted.</FormHelperText>
        </FormControl>
        <FormControl error={false} style={{marginRight: 30, marginLeft: 30}}>
            <InputLabel htmlFor="my-input-depth">Distance to camera (in mm)</InputLabel>
            <Input id="my-input-depth" aria-describedby="my-helper-text-depth" onChange={(e) => handleChange(e, "depth")} value={depth}></Input>
            
            <FormHelperText id="my-helper-text-depth">Only positive numbers are accepted.</FormHelperText>
        </FormControl>
        <Typography variant="body2" color="secondary">
        <img alt="" style={{ width: "100%", height: "", maxWidth: 500, marginTop: 50 }} src={img}></img>
        </Typography>
        <Typography gutterBottom variant="h5" component="div" style={{marginTop: 100}}>
          Number of pixels per smallest feature
        </Typography>
        <Typography variant="body2" color="secondary">
          Give the number of pixels for the smallest feature you want to detect (ex. a QR code module)
        </Typography>
        <FormControl error={false}>
            <InputLabel htmlFor="my-input-pixels">Default value: 2</InputLabel>
            <Input id="my-input-pixels" aria-describedby="my-helper-text-pixels" onChange={(e) => handleChange(e, "pixels")} value={pixels}></Input>
            
            <FormHelperText id="my-helper-text-pixels">Only positive numbers are accepted.</FormHelperText>
        </FormControl>

        </CardContent>
      <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
      <CardActions>
        <Button variant="contained" color="secondary" size="small" onClick={submit}>Go to the next step</Button>
      </CardActions>
      </div>
    </Card>
  );
}