import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

/**
 * Shows different features to choose from
 * @returns 
 */
export default function ResultResolutionCard({cameraResolutionVert, cameraResolutionHor, roundedResX, roundedResY, megaPixel}) {


  return (
    <Card sx={{ maxWidth: 900}} style={{ border: "3px solid #5BDCDF" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Camera resolution
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Camera resolution: {cameraResolutionVert} x {cameraResolutionHor}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Rounded camera resolution: {roundedResX} x {roundedResY}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Rounded number of megapixels: {megaPixel} MP
        </Typography>
      </CardContent>  
      <div style={{display:"flex", justifyContent:"center", width: "100%"}}>

      </div>
    </Card>
  );
}