import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';

/**
 * Shows different features to choose from
 * @returns 
 */
export default function FirstCard({title, img, descr}) {

  const navigate = useNavigate();

  // const [titleSave, settitle] = React.useState(title);

  function goToPage() {
    if (title === "Marker (ex. QR-code)"){
      navigate("/marker");
    } else if (title === "Smallest feature (ex. scratch)"){
      navigate("/scratch");
    } else if (title === "From camera to scene"){
      navigate("/scene");
    } else if (title === "From scene to camera"){
      navigate("/home");
    } else if (title === "Contact us"){
      navigate("/contact");
    } else if (title === "From exposure time to maximum blur"){
      navigate("/blur");
    } else if (title === "From maximum blur to exposure time"){
      navigate("/exposure");
    }
  }

  return (
    <button style={{minWidth: "100%"}} onClick={goToPage}>
    <Card sx={{ maxWidth: 900}} style={{ border: "3px solid #5BDCDF"}} className='card'>
        <img alt="" style={{ height: 140 }} src={img}/>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {descr}
        </Typography>
      </CardContent>
      <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
      </div>
    </Card>
    </button>
  );
}