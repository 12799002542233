import React from 'react';
import '../App/App.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DenseAppBar from '../Header/Header';
import Footer from '../Footer/Footer';
import { Button, Card, CardActions, CardContent, FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

function Scene() {


    const navigate = useNavigate();

    const [size, setSize] = React.useState("");
    const [number_vert, setNumberVert] = React.useState("");
    const [number_hor, setNumberHor] = React.useState("");
    const [distance, setDistance] = React.useState("");
    const [focal, setFocal] = React.useState("");
    const [color, setcolor] = React.useState("blue")
    const [coverage_hor, setCoverageHor] = React.useState("");
    const [coverage_vert, setCoverageVert] = React.useState("");

    function goto() {
        navigate("/")
      }

  const handleChange = (e, type) => {
    console.log("hey")
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (type === "size"){
        setSize(e.target.value);
      } else if (type === "number_hor"){
        setNumberHor(e.target.value);
      } else if (type === "number_vert"){
        setNumberVert(e.target.value);
      } else if (type === "focal"){
        setFocal(e.target.value);
      } else if (type === "distance"){
        setDistance(e.target.value);
    }
      
      console.log(number_hor)
      console.log(size)
    } else {
        setcolor("red")
        console.log(color)
        
    }
  };

  const handleChangeDecimals = (e, type) => {
    console.log("hey")
    const regex = /^\d*\.?\d*$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
        if (type === "size"){
            setSize(e.target.value);
        } else if (type === "focal"){
            setFocal(e.target.value);
        } else if (type === "distance"){
            setDistance(e.target.value);
        }
    }
}

  const submit = () => {
    const regex = /^\d*\.?\d*$/;
    if (!regex.test(size)) {
    alert("Pixel size is not correct or not filled in, try again.")
  } else if (!regex.test(number_hor) || !regex.test(number_vert)) {
    alert("Resolution is not correct or not filled in, try again.")
  } else if (!regex.test(focal)) {
    alert("Focal length is not correct or not filled in, try again.")
  } else if (!regex.test(distance)) {
    alert("Distance is not correct or not filled in, try again.")
  } else if (size === "" || number_vert === "" || number_hor === "" || focal === "" || distance === "") {
    alert("Not all fields are filled in, try again.")
  } else if ((regex.test(size)) && (regex.test(number_vert)) && (regex.test(number_hor))){
        // Calculate sensor dimensions
        const sensor_width = size/1000 * number_hor
        const sensor_height = size/1000 * number_vert
        // Calculate FOV
        const FOV_hor = 2 * Math.atan(sensor_width / (2 * focal))
        const FOV_vert = 2 * Math.atan(sensor_height / (2 * focal))
        // Calculate dimensions of camera coverage
        setCoverageHor(2 * distance * Math.tan(FOV_hor / 2))
        setCoverageVert(2 * distance * Math.tan(FOV_vert / 2))



    //   const smallestfeature = (size / number)
    //   navigate(`/nbpixels`,{ state: { smallestfeature: smallestfeature} })
    }
  }

  return (
    <div>
      <DenseAppBar></DenseAppBar>
    <Container maxWidth="lg" className="App">
    <Typography variant="h4" color="black" style={{margin: 50}}>
          Fill in the camera specifications
    </Typography>
    <Card sx={{ maxWidth: 900}} style={{ border: "3px solid #5BDCDF" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" >
          Pixel size of camera
        </Typography>
        <Typography variant="body2" color="secondary">
        Pixel size in a camera refers to the dimensions of individual light-sensitive units on the camera's sensor, typically measured in micrometers (µm), which affects image resolution and low-light performance.
        </Typography>
        <FormControl error={false}>
            <InputLabel htmlFor="my-input">µm</InputLabel>
            <Input id="my-input" aria-describedby="my-helper-text" onChange={(e) => handleChangeDecimals(e, "size")} value={size}></Input>
            
            <FormHelperText id="my-helper-text">Only positive numbers are accepted.</FormHelperText>
        </FormControl>
        <Typography gutterBottom variant="h5" component="div" style={{marginTop: 100}}>
          Resolution of camera (horizontal and vertical)
        </Typography>
        <Typography variant="body2" color="secondary">
        The resolution of a camera refers to the number of pixels it can capture in an image, typically measured in megapixels (MP), which determines the detail and clarity of the photographs it produces. Fill in the horizontal and vertical resolution of the camera in pixels (P).
        </Typography>
        <FormControl error={false} style={{marginRight: 50}}>
            <InputLabel htmlFor="my-input1">horizontal in pixels</InputLabel>
            <Input id="my-input1" aria-describedby="my-helper-text1" onChange={(e) => handleChange(e, "number_hor")} value={number_hor}></Input>
            <FormHelperText id="my-helper-text1">Only positive numbers are accepted.</FormHelperText>
        </FormControl>
        <FormControl error={false}>
            <InputLabel htmlFor="my-input2">vertical in pixels</InputLabel>
            <Input id="my-input2" aria-describedby="my-helper-text2" onChange={(e) => handleChange(e, "number_vert")} value={number_vert}></Input>
            
            <FormHelperText id="my-helper-text2">Only positive numbers are accepted.</FormHelperText>
        </FormControl>
        <Typography variant="body2" color="secondary">
        {/* <img alt="" style={{ width: "100%", height: "", maxWidth: 500, marginTop: 50 }} src={img}></img> */}
        </Typography>
        <Typography gutterBottom variant="h5" component="div" style={{marginTop: 100}}>
          Focal length
        </Typography>
        <Typography variant="body2" color="secondary">
        The focal length of a lens is the distance between the lens and the image sensor (or film) when the subject is in focus, typically measured in millimeters (mm). It determines the field of view and magnification of the image captured by the camera.
        </Typography>
        <FormControl error={false}>
            <InputLabel htmlFor="my-input3">mm</InputLabel>
            <Input id="my-input3" aria-describedby="my-helper-text3" onChange={(e) => handleChangeDecimals(e, "focal")} value={focal}></Input>
            
            <FormHelperText id="my-helper-text3">Only positive numbers are accepted.</FormHelperText>
        </FormControl>
        <Typography gutterBottom variant="h5" component="div" style={{marginTop: 100}}>
          Distance
        </Typography>
        <Typography variant="body2" color="secondary">
        The distance between the camera and the object being photographed, typically measured in meters (m), which affects the perspective and composition of the image.
        </Typography>
        <FormControl error={false}>
            <InputLabel htmlFor="my-input4">m</InputLabel>
            <Input id="my-input4" aria-describedby="my-helper-text4" onChange={(e) => handleChangeDecimals(e, "distance")} value={distance}></Input>
            
            <FormHelperText id="my-helper-text4">Only positive numbers are accepted.</FormHelperText>
        </FormControl>

        

        </CardContent>
      <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
      <CardActions>
        <Button variant="contained" color="secondary" size="small" onClick={submit}>Calculate scene dimensions of camera coverage</Button>
      </CardActions>
      </div>
    </Card>

    {coverage_hor !== "" && coverage_vert !== "" && <>
    <Card>
    <Typography gutterBottom variant="h5" component="div">
          Exact results
        </Typography>
        {/* <Typography variant="body2" color="text.secondary">
          Field of View: {FOV}
        </Typography> */}
        <Typography variant="body2" color="text.secondary">
          Scene dimensions: {coverage_vert} x {coverage_hor} m
        </Typography>
    </Card></>}



    <Button variant="contained" color="secondary" size="small" onClick={goto}>Start over (to home)</Button>
    </Container>
    <Footer></Footer>
    </div>
  );
}

export default Scene;