import React from 'react';
import '../App/App.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DenseAppBar from '../Header/Header';
import Footer from '../Footer/Footer';
import ThirdCard from '../InputCards/ThirdCard';
import { Button } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import dimensions from './../../Assets/dimensions.png';

function NumberOfPixels() {

    const {state} = useLocation();
    const { smallestfeature } = state; // Read values passed on state
    console.log(smallestfeature)


  const navigate = useNavigate();

  function goto() {
    navigate("/")
  }

  return (
    <div>
      <DenseAppBar></DenseAppBar>
    <Container maxWidth="lg" className="App">
    <Typography variant="h4" color="black" style={{margin: 50}}>
          Calculate FOV and camera resolution
    </Typography>
    <ThirdCard smallestfeature={smallestfeature} img={dimensions}></ThirdCard>
    <Button variant="contained" color="secondary" size="small" onClick={goto}>Start over (to home)</Button>
    </Container>
    <Footer></Footer>
    </div>
  );
}

export default NumberOfPixels;