import React from 'react';
import '../App/App.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DenseAppBar from '../Header/Header';
import Footer from '../Footer/Footer';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import ScratchCard from '../InputCards/ScratchCard';
import explanation from './../../Assets/smallestfeature.png';

function Scratch() {

  const navigate = useNavigate();

  function goto() {
    navigate("/")
  }

  return (
    <div>
      <DenseAppBar></DenseAppBar>
    <Container maxWidth="lg" className="App">
    <Typography variant="h4" color="black" style={{margin: 50}}>
          Give size of smallest feature
    </Typography>
    <ScratchCard descr={"Size of smallest feature"} title={"Size of smallest feature"} unit={'0 mm'} img={explanation}></ScratchCard>
    <Button variant="contained" color="secondary" size="small" onClick={goto}>Start over (to home)</Button>
    </Container>
    <Footer></Footer>
    </div>
  );
}

export default Scratch;