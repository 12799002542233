import React from 'react';
import './../App/App.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FirstCard from '../InputCards/FirstCard';
import DenseAppBar from '../Header/Header';
import Footer from '../Footer/Footer';

function App() {
  return (
    <div>
      <DenseAppBar></DenseAppBar>
    <Container maxWidth="lg" className="App">
    <Typography variant="h4" color="black" style={{margin: 50}}>
          Select type of detection
        </Typography>
      <FirstCard title={'Smallest feature (ex. scratch)'} descr={'Shallow mark or abrasion on the surface of an object, typically caused by a sharp or pointed object making contact with it'} img={'./assets/scratch.jpg'}/>
      <FirstCard title={'Marker (ex. QR-code)'} descr={'Visual symbol or pattern used for tracking and recognition in computer vision applications, often to determine the position and orientation of objects in a 3D space'} img={'./assets/qrchoice.png'}/>
      <FirstCard title={'Contact us'} descr={'Give us feedback or add new features'}/>
    </Container>
    <Footer></Footer>
    </div>
  );
}export default App;