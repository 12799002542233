import React from 'react';
import '../App/App.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DenseAppBar from '../Header/Header';
import Footer from '../Footer/Footer';
import ResultCard from '../InputCards/ResultCard';
import { Button } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import fov from './../../Assets/fov.png';
import resolutions from './../../Assets/resolutions.png';
import DynamicOrStatic from '../InputCards/DynamicOrStatic';

function Marker() {

  const navigate = useNavigate();

  const {state} = useLocation();
  const { cameraResolutionVert, cameraResolutionHor, focalLength, roundedResX, roundedResY,megaPixel,sensorSize, FOV, resolutionsize} = state; // Read values passed on state
  console.log(cameraResolutionHor)
  console.log(cameraResolutionVert)
  console.log("FOV" + FOV)


  function goto() {
    navigate("/")
  }

  return (
    <div>
      <DenseAppBar></DenseAppBar>
    <Container maxWidth="lg" className="App">
    <Typography variant="h4" color="black" style={{margin: 50}}>
          Results for camera hardware
    </Typography>
    <ResultCard cameraResolutionHor={cameraResolutionHor} cameraResolutionVert={cameraResolutionVert} focalLength={focalLength} roundedResX={roundedResX} roundedResY={roundedResY} megaPixel={megaPixel} img={fov} img2={resolutions}></ResultCard>
    <DynamicOrStatic cameraResolutionHor={cameraResolutionHor} cameraResolutionVert={cameraResolutionVert} focalLength={focalLength} roundedResX={roundedResX} roundedResY={roundedResY} megaPixel={megaPixel} sensorSize={sensorSize} FOV={FOV} resolutionsize={resolutionsize}></DynamicOrStatic>
    <Button variant="contained" color="secondary" size="small" onClick={goto}>Start over (to home)</Button>
    </Container>
    <Footer></Footer>
    </div>
  );
}

export default Marker;