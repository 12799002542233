import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';
// import { Alert } from 'react-alert'

/**
 * Shows different features to choose from
 * @returns 
 */
export default function DynamicOrStatic({cameraResolutionVert, cameraResolutionHor, focalLength, roundedResX, roundedResY, megaPixel, sensorSize, FOV, resolutionsize}) {

    const navigate = useNavigate();
    console.log("FOV: " + FOV)

    const submitstatic = () => {
      navigate(`/listofsuppliers`,{ state: { staticObject: true, FOV:FOV, cameraResolutionVert:cameraResolutionVert, cameraResolutionHor:cameraResolutionHor, focalLength:focalLength, roundedResX:roundedResX, roundedResY:roundedResY, megaPixel:megaPixel} })
    }

    const submitdynamicobjects = () => {
        navigate(`/dynamicobjects`,{ state: { FOV:FOV, cameraResolutionVert:cameraResolutionVert, cameraResolutionHor:cameraResolutionHor, focalLength:focalLength, roundedResX:roundedResX, roundedResY:roundedResY, megaPixel:megaPixel, sensorSize:sensorSize,resolutionsize} })
      }

    const submitdynamiccamera = () => {
        navigate(`/dynamiccamera`,{ state: { FOV:FOV, cameraResolutionVert:cameraResolutionVert, cameraResolutionHor:cameraResolutionHor, focalLength:focalLength, roundedResX:roundedResX, roundedResY:roundedResY, megaPixel:megaPixel, resolutionsize} })
    }

  return (
    <Card sx={{ maxWidth: 900}} style={{ border: "3px solid #5BDCDF" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" >
          Dynamic or static
        </Typography>
        <Typography variant="body2" color="secondary">
          Is your camera and object static? Or is one of them dynamic?
        </Typography>
        </CardContent>
      <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
      <CardActions>
        <Button variant="contained" color="secondary" size="small" onClick={submitstatic}>Both static</Button>
      </CardActions>
      <CardActions>
        <Button variant="contained" color="secondary" size="small" onClick={submitdynamicobjects}>Dynamic object</Button>
      </CardActions>
      <CardActions>
        <Button variant="contained" color="secondary" size="small" onClick={submitdynamiccamera}>Dynamic camera</Button>
      </CardActions>
      </div>
    </Card>
  );
}