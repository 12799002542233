import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
// import { Alert } from 'react-alert'

/**
 * Shows different features to choose from
 * @returns 
 */
export default function PickResolutionCard({cameraResolutionHor,  cameraResolutionVert, roundedResX, roundedResY, megaPixel, vertFOV, horFOV, workingDistance, pixelSize}) {

    const navigate = useNavigate();

    // var cameraResolutionHighest = 0;
    // var FOV = vertFOV;
    const [resolutionsize, setResolutionSize] = React.useState(roundedResX);
    const [pixelsize, setPixelSize] = React.useState(3.5);
    const listOfResolutionX = [640, 800, 1024, 1280, 1600, 2048, 2048, 2452, 3200, 1280, 1920]
    const listOfResolutionY = [480, 600, 768, 1024, 1200, 1536, 2048, 2054, 2400, 720, 1080]
    // const listOfPixelSizes = [1.2, 1.7, 2.2, 3.5, 4.6, 6.5, 7, 10, 14]

    const [FOV, setFOV] = React.useState(vertFOV)
    const [cameraResolutionHighest, setcameraResolutionHighest] = React.useState(0)

    const handleChangeResolution = (event) => {
        setResolutionSize(event.target.value);
    };

    const handleChangePixel = (event) => {
        setPixelSize(event.target.value);
    };

    React.useEffect(() => {
        if (cameraResolutionHor > cameraResolutionVert){
            setcameraResolutionHighest(cameraResolutionHor)
            setFOV(horFOV)
        } else {
          setcameraResolutionHighest(cameraResolutionVert)
          setFOV(vertFOV)
        }
        console.log(cameraResolutionHighest)
    }, [cameraResolutionHor, cameraResolutionVert, vertFOV, horFOV, cameraResolutionHighest])

    
    const submit = () => {
        if (pixelsize === ""){
            alert("Pixel size is not correct or not filled in, try again.")
        } else  if (resolutionsize === ""){
            alert("Resolution is not correct or not filled in, try again.")
        } else {
            const sensorSize = resolutionsize*pixelsize/1000
            const focalLength = sensorSize*workingDistance/FOV
            navigate(`/result`,{ state: { cameraResolutionVert: cameraResolutionVert, cameraResolutionHor: cameraResolutionHor, focalLength: focalLength, roundedResX: roundedResX, roundedResY: roundedResY, megaPixel: megaPixel, sensorSize:sensorSize, FOV: FOV, resolutionsize: resolutionsize } })
        }
    }

  return (
    <><Card sx={{ maxWidth: 900}} style={{ border: "3px solid #5BDCDF" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" >
          Choose the camera resolution which you would like to use.
        </Typography>
        <Typography variant="body2" color="secondary">
          In the results above the theoretical camera resolution is calculated and rounded to the nearest fitting industrial camera resolution. This rounding is our recommendation. However, you can choose another value if that suits your needs better.
        </Typography>
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Resolution size</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={resolutionsize}
            label="Resolution Size"
            onChange={handleChangeResolution}
        >
            {listOfResolutionX.map((value, index) => (
                
                <MenuItem key={index} value={value}>{listOfResolutionX[index]}x{listOfResolutionY[index]}</MenuItem>
                // {(value === roundedResX) && (<MenuItem key={index} value={value}>{value}x{listOfResolutionY[index]}</MenuItem>)}
            ))}
                
            <MenuItem value={cameraResolutionHighest > 0 ? cameraResolutionHighest : cameraResolutionVert}>I want to use the theoretical value</MenuItem>
        </Select>
        </FormControl>

        </CardContent>
        { (resolutionsize === cameraResolutionHighest || resolutionsize === cameraResolutionVert) && <Alert severity="warning">Pay attention, you will use the "theoretical" value - this is not realistic!</Alert>}
      <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
      {/* <CardActions>
        <Button variant="contained" color="secondary" size="small" onClick={submit}>Go to the next step</Button>
      </CardActions> */}
      </div>
    </Card>
    <Card sx={{ maxWidth: 900}} style={{ border: "3px solid #5BDCDF" }}>
    <CardContent>
      <Typography gutterBottom variant="h5" component="div" >
        Choose the pixel size you would like to use.
      </Typography>
      <Typography variant="body2" color="secondary">
        Choose the pixel size you would like to use for calculations.
      </Typography>
      <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Pixel size</InputLabel>
      <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={pixelsize}
          label="Pixel Size"
          onChange={handleChangePixel}
      >
          <MenuItem value={1.2}>1.2 μm</MenuItem>
          <MenuItem value={1.7}>1.7 μm</MenuItem>
          <MenuItem value={2.2}>2.2 μm</MenuItem>
          <MenuItem value={3.5}>3.5 μm</MenuItem>
          <MenuItem value={4.6}>4.6 μm</MenuItem>
          <MenuItem value={6.5}>6.5 μm</MenuItem>
          <MenuItem value={7}>7 μm</MenuItem>
          <MenuItem value={10}>10 μm</MenuItem>
      </Select>
      </FormControl>

      </CardContent>
    <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
    <CardActions>
      <Button variant="contained" color="secondary" size="small" onClick={submit}>Go to the next step</Button>
    </CardActions>
    </div>
  </Card></>
  );
}